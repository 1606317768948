<script>
import TravelPriceService from "@/services/TravelPriceService";
import Cloner from "@/libraries/Cloner";

export default {
    props: {
        context: {},
    },
    data() {
        return {
            loading: true,
            editing: false,
            errors: {},
            entity: {
                date_from: "",
                date_to: "",
                price_tax: 0,
                price_commercial: 0,
                id: null,
            },
        };
    },
    created() {
        if (!this.context.empty) {
            this.editing = true;
            this.entity = Cloner.pickClone(this.context, [
                "id",
                "price_tax",
                "price_commercial",
                "date_from",
                "date_to",
            ]);
        }

        this.loading = false;
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await TravelPriceService.PUT(this.entity);
                } else {
                    const r = await TravelPriceService.POST(this.entity);
                }

                this.$emit("save");
            } catch (e) {
                console.log("saveForm exception", e);
                this.errors = e.response.data.errors;
            }
        },
    },
};
</script>


<template>
    <modal
        size="lg"
        :loading="loading"
        :show="true"
        @close="$emit('close')"
        class="asset-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("travel_prices.edit") }}
            </template>
            <template v-else>
                {{ $t("travel_prices.create") }}
            </template>
        </template>

        <template slot="default">
            <v-loader :loading="loading" v-if="loading" />
            <FormulateForm
                v-model="entity"
                @submit="saveForm"
                :errors="errors"
                v-else
            >
                <p class="text-warning">
                    <i class="fa fa-exclamation-triangle mr-2"></i>
                    {{
                        $t(
                            "travel_prices.notice_change_does_not_affect_historical_records"
                        )
                    }}
                </p>
                <div class="row">
                    <div class="col-6">
                        <FormulateInput
                            type="date"
                            name="date_from"
                            :label="$t('travel_prices.date_from')"
                            :validation-name="$t('travel_prices.date_from')"
                            :placeholder="
                                $t('travel_prices.date_from_placeholder')
                            "
                            validation="required"
                        />
                    </div>
                    <div class="col-6">
                        <FormulateInput
                            type="date"
                            name="date_to"
                            :validation-name="$t('travel_prices.date_from')"
                            :label="$t('travel_prices.date_to')"
                            :placeholder="
                                $t('travel_prices.date_to_placeholder')
                            "
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <FormulateInput
                            name="price_tax"
                            type="groupdecimal"
                            :precision="2"
                            align="right"
                            :append="$currency"
                            :label="$t('travel_prices.tax_price_per_km')"
                            :validation-name="
                                $t('travel_prices.tax_price_per_km')
                            "
                            validation="required"
                        />
                    </div>
                    <div class="col-6">
                        <FormulateInput
                            name="price_commercial"
                            type="groupdecimal"
                            :precision="2"
                            align="right"
                            :append="$currency"
                            :label="
                                $t(
                                    'travel_prices.default_price_travel_invoicing'
                                )
                            "
                            :validation-name="
                                $t(
                                    'travel_prices.default_price_travel_invoicing'
                                )
                            "
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            v-if="editing"
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("update") }}</base-button
                        >
                        <base-button
                            v-if="!editing"
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>


<style>
</style>
